import axios from '../_helpers/axios';

export const photoService = {
    add,
};

async function add(data) {
  return new Promise((resolve, reject) => {
    axios.post('upload_image', data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    .then(resp => {
        resolve(resp.data)
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}