const state = {
    type: null,
    message: null
};

const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ dispatch, commit }, message) {
        if(message === 'UnAuthorized'){
          dispatch('users/logout', {}, { root: true });
        }
        commit('error', message);
    },
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {
    success(state, message) {
        state.type = 'alert-success';
        state.message = message;
    },
    error(state, message) {
        state.type = 'alert-danger';
        state.message = message;
        if(message.message === 'Network Error'){
          state.message = "Internet Connection May Not be Available. Please Check Your Internet Connection"
        }
        if(message === 'UnAuthorized'){
          state.message = "Your Session is Expired. Please Login again."
        }
    },
    clear(state) {
        state.type = null;
        state.message = null;
    }
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
};
