export function getCapability(action, user){
  if(user && user.capabilities){
    var capabilities = JSON.parse(JSON.stringify(user.capabilities))
    let cap = capabilities.filter(item => item == action)
    if(cap.length > 0){
      return true
    }
  }
  return false
}

export function isAdmin(user){
  if(user){
    return user.userId < 100
  }
  return false
}
