import axios from '../_helpers/axios';

export const userService = {
    login,
    logout,
    update,
    change_password,
    add,
    edit,
    destroy,
    get_all,
    get,
    profile,
};

async function login(email, password) {
  return new Promise((resolve, reject) => {
    axios.post('login', { email, password })
    .then(resp => {
        const token = resp.data.accessToken
        const user = resp.data
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify(user))
        axios.defaults.headers.common['Authorization'] = token
        resolve(user)
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

function logout() {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  delete axios.defaults.headers.common['Authorization']
}

function update(user) {
  return new Promise((resolve, reject) => {
    axios.put('users/'+user.id, user)
    .then(resp => {
      if(resp.data){
        resolve(resp.data)
      }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  });
}

function change_password(password, repassword) {
  return new Promise((resolve, reject) => {
    axios.post('change_password', {password, repassword})
    .then(() => {
      resolve()
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  });
}

async function get(data) {
  return new Promise((resolve, reject) => {
    axios.get('users/' + data)
    .then(resp => {
      if(resp.data){
        resolve(resp.data)
      }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function add(data) {
  return new Promise((resolve, reject) => {
    axios.post('users', data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.error)
        return
      }
      reject(error.message)
    })
  })
}

async function edit(data) {
  return new Promise((resolve, reject) => {
    axios.put('users/'+data.id, data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function destroy(data) {
  return new Promise((resolve, reject) => {
    axios.delete('users/'+data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function get_all() {
  return new Promise((resolve, reject) => {
    axios.get('users')
    .then(resp => {
        if(resp.data){
          resolve(resp.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function profile() {
  return new Promise((resolve, reject) => {
    axios.get('profile')
    .then(resp => {
        if(resp.data){
          const user = resp.data
          localStorage.setItem('user', JSON.stringify(user))
          resolve(user)
        }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}
