import axios from '../_helpers/axios';

export const reportService = {
  get_income_statement,
  get_balance_sheet,
  get_ledgers,
};

async function get_income_statement(data) {
  return new Promise((resolve, reject) => {
    axios.post('report_income_statement', data)
    .then(resp => {
        resolve(resp.data)
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function get_balance_sheet(data) {
  return new Promise((resolve, reject) => {
    axios.post('report_balance_sheet', data)
    .then(resp => {
        resolve(resp.data)
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function get_ledgers(data) {
  return new Promise((resolve, reject) => {
    axios.post('report_ledger', data)
    .then(resp => {
        resolve(resp.data)
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}
