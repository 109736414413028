import { familyService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, families: [], family: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        familyService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        familyService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/families');
                    dispatch('alert/success', "Family Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/families');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        familyService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/families');
                    dispatch('alert/success', "Family Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/families');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        familyService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      familyService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/families');
                  dispatch('alert/success', "Family Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/families');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  families: state => {
    return state.families
  },
  family: state => {
    return state.family
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.family = {};
        state.families = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.family = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.families = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const families = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
