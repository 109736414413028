import { checklistService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, checklists: [], checklist: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        checklistService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        checklistService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/checklists');
                    dispatch('alert/success', "Checklist Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/checklists');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        checklistService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/checklists');
                    dispatch('alert/success', "Checklist Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/checklists');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }, data) {
        commit('request');

        checklistService.get_all(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      checklistService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/checklists');
                  dispatch('alert/success', "Checklist Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/checklists');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  checklists: state => {
    return state.checklists
  },
  checklist: state => {
    return state.checklist
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.checklist = {};
        state.checklists = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.checklist = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.checklists = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const checklists = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
