import { event_typeService } from '../_services';

const state = { onRequest: false, event_types: []};

const actions = {
    get_all({ dispatch, commit }) {
        commit('request');

        event_typeService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  event_types: state => {
    return state.event_types
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.event_types = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.event_types = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const event_types = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
