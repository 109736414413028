import axios from '../_helpers/axios';

export const budget_period_typeService = {
    get_all,
};


async function get_all() {
  return new Promise((resolve, reject) => {
    axios.get('budget_period_types')
    .then(resp => {
        if(resp.data){
          resolve(resp.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}
