import { photoService } from '../_services';

const state = { onRequest: false, photo: {} };

const actions = {
    add({ dispatch, commit }, data) {
        commit('request');

        photoService.add(data)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  photo: state => {
    return state.photo
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.photo = {};
        state.photos = [];
    },
    success_data(state, data) {
      state.onRequest = false;
      state.photo = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const photos = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
