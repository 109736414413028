import axios from '../_helpers/axios';

export const checklistService = {
    get,
    add,
    edit,
    destroy,
    get_all,
};

async function get(data) {
  return new Promise((resolve, reject) => {
    axios.get('checklists/' + data)
    .then(resp => {
      if(resp.data){
        resolve(resp.data)
      }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function add(data) {
  return new Promise((resolve, reject) => {
    axios.post('checklists', data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function edit(data) {
  return new Promise((resolve, reject) => {
    axios.put('checklists/'+data.id, data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function destroy(data) {
  return new Promise((resolve, reject) => {
    axios.delete('checklists/'+data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function get_all(data) {
  return new Promise((resolve, reject) => {
    axios.get('checklists?familyId='+data.familyId)
    .then(resp => {
        if(resp.data){
          resolve(resp.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}
