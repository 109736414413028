import {createWebHistory, createRouter} from 'vue-router'

import Home from '../home/Home.vue';
import Login from '../login/Login.vue';
import Profile from '../profile/Profile.vue'

import Family from '../family/Family.vue'
import AddFamily from '../family/AddFamily.vue'
import EditFamily from '../family/EditFamily.vue'

import User from '../user/User.vue'
import AddUser from '../user/AddUser.vue'
import EditUser from '../user/EditUser.vue'
import EditUserCapability from '../user_capability/EditUserCapability.vue'

import Currency from '../currency/Currency.vue'
import AddCurrency from '../currency/AddCurrency.vue'
import EditCurrency from '../currency/EditCurrency.vue'

import Coa from '../coa/Coa.vue'
import AddCoa from '../coa/AddCoa.vue'
import EditCoa from '../coa/EditCoa.vue'

import Transaction from '../transaction/Transaction.vue'
import AddTransaction from '../transaction/AddTransaction.vue'
import EditTransaction from '../transaction/EditTransaction.vue'

import TransactionTemplate from '../transaction_template/TransactionTemplate.vue'
import AddTransactionTemplate from '../transaction_template/AddTransactionTemplate.vue'
import EditTransactionTemplate from '../transaction_template/EditTransactionTemplate.vue'

import ReportIncomeStatement from '../report/ReportIncomeStatement.vue'
import ReportBalanceSheet from '../report/ReportBalanceSheet.vue'
import ReportLedger from '../report/ReportLedger.vue'

import Event from '../event/Event.vue'
import AddEvent from '../event/AddEvent.vue'
import EditEvent from '../event/EditEvent.vue'

import Checklist from '../checklist/Checklist.vue'
import AddChecklist from '../checklist/AddChecklist.vue'
import EditChecklist from '../checklist/EditChecklist.vue'

import Budget from '../budget/Budget.vue'
import AddBudget from '../budget/AddBudget.vue'
import EditBudget from '../budget/EditBudget.vue'

import NotFound from '../notfound/NotFound.vue'

const routes = [
  {
    path: '/',
    component: Home,
    name: 'home',
    children: [
      {
        path: '/profile',
        component: Profile,
        name: 'profile'
      },

      //FAMILIES
      {
        path: '/families',
        component: Family,
        name: 'families'
      },
      {
        path: '/families/add',
        component: AddFamily,
        name: 'add_family'
      },
      {
        path: '/families/edit/:id',
        component: EditFamily,
        name: 'edit_family'
      },

      //USERS
      {
        path: '/users',
        component: User,
        name: 'users'
      },
      {
        path: '/users/add',
        component: AddUser,
        name: 'add_user'
      },
      {
        path: '/users/edit/:id',
        component: EditUser,
        name: 'edit_user'
      },

      //USER CAPABILITY
      {
        path: '/user_capabilities/edit/:id',
        component: EditUserCapability,
        name: 'edit_user_capability'
      },
      
      //CURRENCIES
      {
        path: '/currencies',
        component: Currency,
        name: 'currencies'
      },
      {
        path: '/currencies/add',
        component: AddCurrency,
        name: 'add_currency'
      },
      {
        path: '/currencies/edit/:id',
        component: EditCurrency,
        name: 'edit_currency'
      },

      //COAS
      {
        path: '/coas',
        component: Coa,
        name: 'coas'
      },
      {
        path: '/coas/add',
        component: AddCoa,
        name: 'add_coa'
      },
      {
        path: '/coas/edit/:id',
        component: EditCoa,
        name: 'edit_coa'
      },

      //TRANSACTION TEMPLATES
      {
        path: '/transaction_templates',
        component: TransactionTemplate,
        name: 'transaction_templates'
      },
      {
        path: '/transaction_templates/add',
        component: AddTransactionTemplate,
        name: 'add_transaction_template'
      },
      {
        path: '/transaction_templates/edit/:id',
        component: EditTransactionTemplate,
        name: 'edit_transaction_template'
      },

      //TRANSACTIONS
      {
        path: '/transactions',
        component: Transaction,
        name: 'transactions'
      },
      {
        path: '/transactions/add',
        component: AddTransaction,
        name: 'add_transaction'
      },
      {
        path: '/transactions/edit/:id',
        component: EditTransaction,
        name: 'edit_transaction'
      },

      //REPORTS
      {
        path: '/report_income_statement',
        component: ReportIncomeStatement,
        name: 'report_income_statement'
      },
      {
        path: '/report_balance_sheet',
        component: ReportBalanceSheet,
        name: 'report_balance_sheet'
      },
      {
        path: '/report_ledger',
        component: ReportLedger,
        name: 'report_ledger'
      },

      //EVENTS
      {
        path: '/events',
        component: Event,
        name: 'events'
      },
      {
        path: '/events/add',
        component: AddEvent,
        name: 'add_event'
      },
      {
        path: '/events/edit/:id',
        component: EditEvent,
        name: 'edit_event'
      },
      
      //CHECKLISTS
      {
        path: '/checklists',
        component: Checklist,
        name: 'checklists'
      },
      {
        path: '/checklists/add',
        component: AddChecklist,
        name: 'add_checklist'
      },
      {
        path: '/checklists/edit/:id',
        component: EditChecklist,
        name: 'edit_checklist'
      },
      
      //BUDGETS
      {
        path: '/budgets',
        component: Budget,
        name: 'budgets'
      },
      {
        path: '/budgets/add',
        component: AddBudget,
        name: 'add_budget'
      },
      {
        path: '/budgets/edit/:id',
        component: EditBudget,
        name: 'edit_budget'
      },

      { path: '/notFound', component: NotFound },
    ]
  },
  {
    path: '/login',
    component: Login,
    name: 'login'
  },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  if (!to.matched.length) {
    if (loggedIn) {
      next('/notFound');
      return;
    } else {
      next('/login');
      return;
    }
  }
  if(authRequired) {
    if (loggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    if (loggedIn) {
      next('/')
      return
    }
    next()
  }
})
