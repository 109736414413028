import { createStore } from 'vuex'
import { alert } from './alert.module';
import { families } from './families.module';
import { users } from './users.module';
import { user_capabilities } from './user_capabilities.module';
import { currencies } from './currencies.module';
import { coas } from './coas.module';
import { coa_categories } from './coa_categories.module';
import { transactions } from './transactions.module';
import { transaction_templates } from './transaction_templates.module';
import { reports } from './reports.module';
import { photos } from './photos.module';
import { events } from './events.module';
import { event_types } from './event_types.module';
import { checklists } from './checklists.module';
import { budgets } from './budgets.module';
import { budget_period_types } from './budget_period_types.module';

export const store = createStore({
  modules: {
      alert,
      families,
      users,
      user_capabilities,
      currencies,
      coas,
      coa_categories,
      transactions,
      transaction_templates,
      photos,
      events,
      event_types,
      reports,
      checklists,
      budgets,
      budget_period_types,
  }
})
