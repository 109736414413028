import { reportService } from '../_services';

const state = { onRequest: false, reports: [], reportLedgers: [] };

const actions = {
    get_income_statement({ dispatch, commit }, data) {
        commit('request');

        reportService.get_income_statement(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_balance_sheet({ dispatch, commit }, data) {
        commit('request');

        reportService.get_balance_sheet(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_ledgers({ dispatch, commit }, data) {
        commit('request');

        reportService.get_ledgers(data)
            .then(
                (resp) => {
                    commit('success_data_ledgers', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  reports: state => {
    return state.reports
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.reports = [];
        state.reportLedgers = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.reports = data;
    },
    success_data_ledgers(state, data) {
      state.onRequest = false;
      state.reportLedgers = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const reports = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
