import { budget_period_typeService } from '../_services';

const state = { onRequest: false, budget_period_types: [] };

const actions = {
    get_all({ dispatch, commit }) {
        commit('request');

        budget_period_typeService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  budget_period_types: state => {
    return state.budget_period_types
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.budget_period_type = {};
        state.budget_period_types = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.budget_period_types = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const budget_period_types = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
